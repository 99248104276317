import {graphql, Link} from 'gatsby';
import React from 'react';

import {Button} from '../../../components/button';
import {Cta} from '../../../components/cta';
import {Layout} from '../../../components/layout';

const locale = 'en';

export default ({data}: any) => (
    <Layout
        title="Kick-off, get us on board with your product vision – Step 1 of our approach"
        description="During the kick-off session we decide together on the essential requirements for the software product, based on the product vision."
        ogImage="kickoff-en.png"
        translatedPagePath="/nl/werkwijze/kick-off/"
        locale={locale}>
        <Link to="/en/approach/prototyping/" className="body__nav body__nav--right" title="Approach step 2. Prototype">
            Next step
        </Link>

        <section className="section">
            <article className="content content--no-hero">
                <div className="container">
                    <div className="content__display">
                        <h2>
                            {/* Make sure to have the correct spacing when br becomes invisible */}
                            Get us <br /> on board <br /> with your <br /> product vision
                        </h2>
                        <div className="display__number">1</div>
                    </div>
                    <div className="content__copy">
                        <p className="p-lead">
                            The process starts with discovering what we want to create. During the kick-off session we
                            decide together on the essential requirements for the software product, based on the product
                            vision.
                        </p>
                        <p>
                            To understand the essence of the product we start by answering some questions together, such
                            as: "Who are you?", "What problem does the product need to solve?" and "Who are the users
                            and what do they want?" Together we pin down the specifics of the{' '}
                            <span className="highlight">product vision</span>.
                        </p>
                        <p>
                            We analyse the product’s aims and ambitions together. In doing so we take a critical
                            approach, so we can identify and establish the main{' '}
                            <span className="highlight">product risks</span> immediately.
                        </p>
                        <p>
                            Using this knowledge we prepare a{' '}
                            <span className="highlight">provisional development plan</span> and provisional development
                            schedule for the product and draft the objectives to be achieved. We then start the
                            prototype phase, in which we specify the plan in further detail by removing the most
                            significant unknown factors.
                        </p>
                        <ul className="list">
                            <li className="ion-md-arrow-dropright">Getting round the table together</li>
                            <li className="ion-md-arrow-dropright">&#189; &ndash; 2 days</li>
                            <li className="ion-md-arrow-dropright">1 &ndash; 3 specialists</li>
                        </ul>
                    </div>
                    <div className="content__buttons content__buttons--50">
                        <Button
                            url="/en/approach/prototyping/"
                            name="Next step: prototyping"
                            title="Approach step 2. prototype"
                        />
                    </div>
                </div>
            </article>

            <Cta image={data.olav.childImageSharp.fixed} locale={locale} />
        </section>
    </Layout>
);

export const query = graphql`
    query {
        olav: file(relativePath: {eq: "people/olav_2_zoom.jpg"}) {
            ...inlineAvatarImage
        }
    }
`;
